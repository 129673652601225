/* https://www.vandelaydesign.com/pure-css-slidein-nav-menu/ */

/** navigation style **/
ul.nav {
  display: block;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: uppercase;
  font-weight: bold;
}

ul.nav > li {
  display: block;
  float: left;
  line-height: 2rem;
  padding: 0 14px;
  margin: 0;
  position: relative;
}

ul li a {
  position: relative;
  text-decoration: none;
  color: black;
}

ul.nav li > ul {
  position: absolute;
  display: none;
  z-index: 99;
  padding: 0;
}

ul.nav li > ul li {
  position: relative;
  display: block;
  color: #fff;
  z-index: 999;
}

ul.nav li > ul li a {
  color: #eee;
  display: block;
  padding: 0 20px;
}
/* sub menu links*/
ul.nav li > ul li a:hover { color: #474747; }

/* main menu links*/
ul.nav > li:hover > a { color: cadetblue; }
ul.nav li:hover > ul {
  display: block;
  left: 0;
  margin: 0;
}

ul.nav li:hover > ul > li {
  display: block;
  left: 0;
  margin: 0;
}


ul.nav:first-child li > ul:before,
ul.nav:first-child li > ul:after {
  content: " ";
  position: absolute;
  background-color: #769cb7;
}

ul.nav:first-child li > ul:after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

ul.nav:first-child li:hover > ul:before,
ul.nav:first-child li:hover > ul:after {
  animation: nav-down 0.15s;
}

/** big sub-menu dropdown arrow **/
ul.nav:first-child > li:hover > a:not(:last-child):after {
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: 36px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #474747;
  z-index: 9999;
}

/** little link arrow **/
ul.nav:first-child > li > span:not(:last-child):after {
  position: absolute;
  display: inline-block;
  content: " ";
  width: 0px;
  height: 0px;
  top: 50%;
  margin-top: -2px;
  margin-left: 4px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;

}

/** Dropdown Animation **/
@keyframes nav-down {
  0% { height: 0px; }
  100% { height: 100%; }
}

/** Dropdown Animation fadeIN **/
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/** Sub-Nav Sliding Animation **/
@keyframes nav-anim-left {
  0% { left: 100%; }
  100% { left: -3%; }
}